import React from 'react'
import "./footer.css"
class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <section className="footer-section">
          <div className="container">
            <h4 className="title">NEED ADVICE ABOUT PROTECTING YOUR INTELLECTUAL PROPERTY?</h4>
            <div>
              <button
                onClick={this.goContact}
                className="footer-btn">
                CONTACT LAUNCHPAD PATENTS
                </button>
            </div>
            <br />
            {/* <p className="patent-text" style={{color: 'white'}}><small>Patent Pending</small></p> */}
          </div>
        </section>
      </div>

    )
  }
  goContact() {
    window.open("https://launchpadpatents.com/contact/", "_blank")
  }
}

export default Footer
