/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { NotificationContainer } from "react-notifications"
import "react-notifications/lib/notifications.css"

//import { useStaticQuery, graphql } from "gatsby"
import CircularProgress from "@material-ui/core/CircularProgress"

//import Header from "./header"
import Footer from "./footer"
import "./layout.css"
const ignoreWarningsLifeCycles = () => {
  console.warn = e => {
    const warnKeyToIgnore = "deprecated lifecycles to their new names"
    if (typeof e ==='string' && !e.match(warnKeyToIgnore)) {
      console.log(`WARNING:${e}`)
    }
  }
}
const ignoreLTEPropErrors = () => {
  console.error = e => {
    const errKeyToIgnore = "Failed prop type"
    if (typeof e ==='string' && !e.match(errKeyToIgnore)) {
      console.log	(
        `%c ERR : ${e}`,
        "color: #ff0000"
      )
  
    }
  }
}
const Layout = ({ children, hideFooter, inFetch }) => {
  ignoreWarningsLifeCycles()
  ignoreLTEPropErrors()
  /* const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 */
  return (
    <>
      {!inFetch ?
        (<>
          <main>{children}</main>
          <NotificationContainer />
        </>):(
          <div className="progress-layout">
            <CircularProgress />
          </div>
        )}
      {!hideFooter && <Footer></Footer>}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
  inFetch: PropTypes.bool,
}

export default Layout
